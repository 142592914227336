<template>
  <div>
    <div class="mass-action-settings">


      <div>
        Подстановки:
        <div>{user_name} - Имя пользователя</div>
        <div>{user_surname} - Фамилия пользователя</div>
        <div>{user_middlename} - Отчество пользователя</div>
        <div>{goals_page} - Ссылка на страницу карт</div>
      </div>
      <div style="margin-bottom: 10px">

        <el-input
          v-model="actionData.text"
          style="width: 700px"
          type="textarea"
          :autosize="{ minRows: 6}"
        >
        </el-input>
      </div>








      <el-popconfirm
        confirm-button-text='Да'
        cancel-button-text='нет'
        icon="el-icon-question"
        title="Вы уверены, что хотите отправить уведомление?"
        @confirm="doAction"
      >
        <el-button
          slot="reference"
          size="small"
          type="primary"
          :disabled="actionButtonDisabled"
        >
          Запустить
        </el-button>

      </el-popconfirm>

    </div>

    <mass-action-result
      v-if="result"
      :result="result"
    ></mass-action-result>
  </div>
</template>

<script>
import {ActionTemplateMixin} from "@/components/massActions/actionTemplates/ActionTemplateMixin";
import MassActionResult from "@/components/massActions/MassActionResult.vue";
import AddUsersDialog from "@/components/user/AddUsersDialog.vue";

export default {
  name: "user-send-notification-action",
  mixins: [ActionTemplateMixin],
  components: {MassActionResult},

  computed: {
    actionButtonDisabled() {
      return !this.actionData.text;
    }
  },
  watch: {},
  data() {
    return {
      actionData: {
        text: null,
      }
    }
  },
  methods: {
    userSelected(user){
      this.user = user;
    }
  },
}
</script>
